import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide85/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide85/image/img.png"
import slider1 from "@components/pageGuide/guides/guide85/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide85/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide85/image/silder3.png"


const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"    >
      Política de Privacidad.
    </a>
  </span>
)

const data = {
  start: {
    support: "Guía | Logística",
    title: <h1 className="titles-underlined-ebook ebook-ventas">
      Cómo automatizar la gestión de entregas de última milla con WhatsApp
    </h1>,
    description: <p className="text-h2-ebook guide__description">
      La automatización mejora la gestión de entregas y optimiza el uso de recursos. Utiliza WhatsApp para enviar actualizaciones en tiempo real sobre el estado de los pedidos. Implementa notificaciones para confirmar envíos y reducir consultas innecesarias.
    </p>,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-9.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Confirmaciones automáticas de pedidos en tiempo real.",
      },
      {
        key: 2,
        text: "Notificaciones automáticas sobre el estado del envío."
      },
      {
        key: 3,
        text: "Gestión de cambios mediante mensajería instantánea.",
      },
      {
        key: 4,
        text: "Integración de bot para consultas frecuentes de entregas.",
      },
      {
        key: 5,
        text: "Coordinación automática con conductores y clientes.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },
  description: {
    cards: [
      {
        text: <p>
          Recuerda que, con esta guía, podrás conocer cómo coordinar entregas de manera eficiente y mejorar la comunicación con clientes y repartidores. Programa mensajes para gestionar cambios y resolver imprevistos sin intervención manual.
          <br /><br />
          Por último, accede a más detalles, ejemplos prácticos y herramientas recomendadas descargándola. Optimiza la logística con soluciones automatizadas.
        </p>,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
